<template>
  <div>
    <v-container
      ><p>Identify the correct symbol and description for each of the following:</p></v-container
    >
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <td>Quantum number</td>
            <td>Symbol</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(question, index) in questions" :key="question">
            <td>{{ question }}</td>
            <td>
              <template :content="symbolOptions.text">
                <v-select
                  v-model="inputs[`input${index + 1}`][0]"
                  class="symbols"
                  :items="symbolOptions"
                >
                  <template #selection="{item}">
                    <stemble-latex class="no-text-transform" :content="item.text" />
                  </template>
                  <template #item="{item}">
                    <stemble-latex class="no-text-transform" :content="item.text" />
                  </template>
                </v-select>
              </template>
            </td>
            <td>
              <v-select
                v-model="inputs[`input${index + 1}`][1]"
                class="descriptions"
                :items="descriptionOptions"
              ></v-select>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'MatchSymbolToQuantumNumber',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: [],
        input3: [],
        input4: [],
      },
      symbolOptions: [
        {text: '$n$', value: 'n'},
        {text: '$l$', value: 'l'},
        {text: '$m_l$', value: 'ml'},
        {text: '$m_s$', value: 'ms'},
      ],
      descriptionOptions: [
        'orbital shape',
        'electron spin',
        'orbital orientation',
        'orbital size/energy',
      ],
      questions: [
        'Magnetic quantum number',
        'Spin quantum number',
        'Principal quantum number',
        'Angular momentum quantum number',
      ],
    };
  },
};
</script>
<style>
.symbols {
  width: 60px;
  overflow: hidden;
}
.descriptions {
  width: 150px;
  overflow: hidden;
}
</style>
